//import { setSessionStorage } from '@/utils/storage.js';

const preventBack = {
  methods: {
    prevent_back(cb) {
      let path = this.$route.fullPath
      if (this.$router.mode == 'hash') {
        path = '#' + path
      }
      let url = '/' + process.env.VUE_APP_ROUTER_BASE + path
      window.pushStateFun = function () {
        console.log('监听到popstate')
        let state = {
          title: null,
          url
        }
        window.history.pushState(state, null, url)
        cb && cb()
      }
      var state = {
        title: null,
        url
      }
      window.history.pushState(state, null, url)
      window.addEventListener('popstate', window.pushStateFun, false)
    }
  },
  mounted() {
    if (this.$vnode && this.$vnode.data.keepAlive) {
      return
    } else {
      //setSessionStorage('_isBack', 'coupons');
      this.prevent_back(this.MIXINpreventBackCb)
    }
  },
  activated() {
    this.prevent_back()
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('popstate', window.pushStateFun, false)
    next()
  }
}
export default preventBack
