<template>
  <div
    class="container"
    v-bg="{
      remoteSrc: imgData.backgroundImg,
      localSrc: require('@/assets/back-bg.png')
    }"
  >
    <div class="panel">
      <div
        class="panel__btn"
        @click="onAssistant"
        v-tracking="{ userVisit: '8' }"
      >
        个人中心
      </div>
      <div class="tips">
        <p class="p1">康之味促销活动已结束</p>
        <p class="p2">活动截止日期：2022年12月31日</p>
      </div>
      <div class="panel__btn" @click="onActivityRule">活动规则</div>
    </div>
    <!-- 圈码条 -->
    <qcode-bar></qcode-bar>
    <!-- 剩余抽奖次数 -->
    <draw-number class="draw-number"></draw-number>

    <!-- 转盘 -->
    <Turntable
      class="m-turntable"
      ref="turntable"
      :initUrl="getTurntableImage"
      :drawUrl="getDrawRecord"
      :disabled="m_disabled"
      @onStart="$_onStartTurntable"
      @drawAfter="$_drawAfter"
      @fetchCb="getFetchData"
      :tracking="drawTrackingParams"
    />
    <!-- 问题咨询/隐私声明 -->
    <helper></helper>
    <!-- 中奖名单 -->
    <!-- <winners></winners> -->
    <!-- 活动规则 -->
    <activity-rule ref="activityRule"></activity-rule>
    <!-- 中奖记录 -->
    <winningRecord ref="winningRecord"></winningRecord>
  </div>
</template>

<script>
import {
  queryTurntableImagesAndSweepstakesRules,
  drawRecord
} from '@/api/index.js'

import { drawMixin, qrcodeScanMixin } from '@/mixins/index'
import preventBack from '@/mixins/prevent-browser-back'
import { getSessionStorage } from '@/utils/storage.js'

export default {
  name: 'Back',
  mixins: [drawMixin, preventBack, qrcodeScanMixin],
  data() {
    return {
      imgData: {},
      m_initQueryLotteryNumber: false,
      pseudoMode: false,
      drawTrackingParams: {
        userVisit: '3',
        back:
          getSessionStorage('backValue') ||
          function () {
            return 'null'
          }
      }
    }
  },
  watch: {
    $route: {
      handler: function (obj) {
        console.log('$route obj', obj)
        // 二维码首次使用时间
        this.$nextRequest(this.$_getQueryQrcodeScanFirstTime)
        // 重置转盘指针
        this.$nextTick(() => {
          this.$refs['turntable'].init()
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //获取抽奖画面图片和抽奖规则
    getTurntableImage() {
      return queryTurntableImagesAndSweepstakesRules({
        rotaryType: 3
      })
    },
    // 抽奖接口
    getDrawRecord() {
      return drawRecord()
    },
    getFetchData(data) {
      this.imgData = data
      console.log('getFetchData', data)
    },
    MIXINpreventBackCb() {
      window.location.reload()
    },
    // 点击助手
    onAssistant() {
      this.$refs.winningRecord.open()
    },
    // 活动规则
    onActivityRule() {
      this.$refs.activityRule.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 1570px;
  padding-top: 16px;
  background-size: 100%;
  background-repeat: no-repeat;
  .panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 434px;
    padding: 0 20px;
    width: 718px;
    height: 110px;
    border-radius: 10px;
    background-color: rgba(53, 126, 196, 0.42);
    text-align: center;
    border: 1px solid rgba(53, 126, 196, 1);
    .tips {
      margin: 0 auto;
      display: inline-block;
      text-align: center;
    }
    .p1 {
      margin-bottom: 10px;
      font-size: 31px;
      color: #e8e8e8;
      font-weight: bold;
      line-height: 100%;
    }
    .p2 {
      font-size: 18px;
      color: #e8e8e8;
      line-height: 100%;
    }
    .panel__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 124px;
      height: 52px;
      font-size: 20px;
      color: #fff;
      background-color: rgba(53, 126, 196, 0.42);
      border-radius: 30px;
    }
  }
  .draw-number {
    margin-top: -30px;
    padding: 0 0 50px;
  }
  .helper--1 {
    padding-bottom: 160px;
  }
}
</style>
